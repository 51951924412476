<template>
  <div class="mt-page">
    <div class="mt-section tw-py-12">
      <div class="mt-content-wrapper">
        <div class="tw-text-center tw-mb-10">
          <h2>Get started</h2>
          <div class="subtitle">Start by completing a few simple questions</div>
        </div>
        <v-input
          :error-messages="!!errorMessage[item.key] ? errorMessage[item.key] : ''"
          v-for="item in questions"
          :key="item.key"
        >
          <div class="tw-w-full tw-mb-2">
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-w-full">
              <div class="tw-text-right lg:tw-w-1/2 lg:tw-pr-2 tw-mb-2 lg:tw-mb-0">
                <span class="question tw-text-black">{{ item.question }}</span>
              </div>
              <div class="lg:tw-w-1/2 lg:tw-pl-2">
                <v-btn-toggle
                  borderless
                  v-model="local[item.key]"
                  :mandatory="!!local[item.key]"
                  class="toggle"
                  :options="item.options"
                  @change="updateField(item.key)"
                >
                  <v-btn
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value"
                  >{{ option.label }}</v-btn>
                </v-btn-toggle>
              </div>
            </div>
            <div
              v-if="item.key === 'gender' && local[item.key] === 'other'"
              class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-w-full"
            >
              <div class="tw-text-right lg:tw-w-1/2 lg:tw-pr-2 tw-mb-2 lg:tw-mb-0">
              </div>
              <div class="lg:tw-w-1/2 lg:tw-pl-2">
                <v-text-field
                  v-model="otherGender"
                  hide-details
                  v-bind="textField"
                  class="tw-w-full tw-mt-4"
                  label="Preferred term"
                  @input="updateGender()"
                />
              </div>
            </div>
          </div>
        </v-input>

        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-w-full">
          <div class="tw-text-right lg:tw-w-1/2 lg:tw-pr-2 tw-mb-2 lg:tw-mb-0">
          </div>
          <div class="lg:tw-w-1/2 lg:tw-pl-2">
            <v-btn
              :color="isValid ? 'primary' : 'rgb(158, 158, 158)'"
              dark
              depressed
              class="tw-font-medium tw-rounded-lg tw-px-24 link"
              label="Continue"
              :tag="isValid ? 'a' : 'button'"
              :href="isValid ? signupUrl : null"
              target="_blank"
              @click="submitted = true"
            >Continue</v-btn>
          </div>
        </div>
      </div>
    </div>
    <anon-tracking />
  </div>
</template>

<script>
import AnonTracking from '../../comps/AnonTracking'
export default {
  name: 'PageEmbedMyTributes',
  components: { AnonTracking },
  data: () => ({
    local: {
      adult: null,
      australia: null,
      partner: null,
      children: null,
      gender: null,
    },
    otherGender: '',
    submitted: false,
    questions: [
      {
        key: 'adult',
        question: 'Are you over 18 years old?',
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        key: 'australia',
        question: 'Do you live in Australia?',
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        key: 'partner',
        question: 'What is your relationship status?',
        options: [
          { label: 'Single', value: 'single' },
          { label: 'Married', value: 'married' },
          { label: 'Defacto', value: 'defacto' },
        ],
      },
      {
        key: 'children',
        question: 'Do you have children under 18?',
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        key: 'gender',
        question: 'Which best describes you?',
        options: [
          { label: 'Female', value: 'female' },
          { label: 'Male', value: 'male' },
          { label: 'Other', value: 'other' },
        ],
      },
    ],
  }),
  computed: {
    isValid() {
      return Object.values(this.local).filter(val => {
        return val === null
      }).length === 0 &&
        this.local.adult === 'yes' &&
        this.local.australia === 'yes'
    },
    errorMessage() {
      const msgs = {}
      Object.keys(this.local).forEach(key => {
        msgs[key] = this.local[key] === null && this.submitted ? 'This field is required' : null
      })

      msgs.adult = (this.local.adult === 'no') ? 'You must be over 18 to complete a Will through Willed' : msgs.adult
      msgs.australia = (this.local.australia === 'no') ? 'You must live in Australia complete a Will through Willed' : msgs.australia

      return msgs
    },
    localFormatted() {
      const formatted = {}
      Object.keys(this.local).forEach((key) => {
        if (key !== 'adult' && key !== 'australia' && this.local[key]) {
          formatted[key] = this.local[key]
          if (key === 'gender' && formatted[key] === 'other' && this.otherGender.trim().length > 0) {
            formatted[key] = this.otherGender.trim()
          }
        }
      })

      return formatted
    },
    signupUrl() {
      return `${window.location.origin}/register?q=${this.encrypt}`
    },
    encrypt() {
      const q = {
        ...(Object.keys(this.localFormatted).length > 0 && this.localFormatted),
        ...(Object.keys(this.$route.query).length > 0 && this.$route.query),
      }
      return encodeURIComponent(btoa(JSON.stringify(q)))
    },
  },
  methods: {
    updateField(key) {
      if (this.localFormatted[key]) {
        this.$store.commit('anon/SET_ANON_DATA', {
          key: key,
          value: this.localFormatted[key],
        })
      }
    },
    updateGender() {
      if (this.localFormatted.gender) {
        this.$store.commit('anon/SET_ANON_DATA', {
          key: 'gender',
          value: this.localFormatted.gender,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.mt-page .toggle {
  border: 2px solid #000;
  border-radius: 8px;
  overflow: hidden;

  button {
    text-transform: none;
    border-radius: 0px !important;
    font-weight: 500;
    min-width: 80px !important;
    height: 40px !important;
    background-color: #FFFFFF !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border-left: 2px solid #000 !important;
    font-size: 16px;
    letter-spacing: 1px;

    &.v-btn--active {
      background-color: #000000 !important;
      color: #FFFFFF;
    }

    &:first-child {
      border-left: none !important;
    }
  }
}

.mt-page .v-btn.link {
  height: 62px !important;
  font-size: 24px;
  text-transform: none !important;
}

.mt-page .q-field__bottom {
  text-align: center;
}

.mt-page .subtitle {
  font-size: 22px;
}

.mt-page .question {
  font-size: 16px;
  font-weight: bold;
}

.mt-page h2 {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.2em;
  margin: 0 0 8px;
}

.mt-content-wrapper {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 24px;
}

.mt-page {
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: #000000 !important;
  }
  .v-messages {
    text-align: center;
    min-height: 16px;
  }
  .tw-mt-4 {
    margin-top: 1rem !important;
  }

  .tw-mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .tw-mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .tw-flex {
    display: flex !important;
  }

  .tw-w-full {
    width: 100% !important;
  }


  .tw-flex-col {
    flex-direction: column !important;
  }

  .tw-items-center {
    align-items: center !important;
  }

  .tw-rounded-lg {
    border-radius: 0.5rem !important;
  }

  .tw-px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .tw-py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .tw-text-center {
    text-align: center !important;
  }

  .tw-text-right {
    text-align: right !important;
  }

  .tw-font-medium {
    font-weight: 500 !important;
  }

  .tw-text-black {
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }


  @media (min-width: 1024px) {
    .lg\:tw-mb-0 {
      margin-bottom: 0px !important;
    }

    .lg\:tw-w-1\/2 {
      width: 50% !important;
    }

    .lg\:tw-flex-row {
      flex-direction: row !important;
    }

    .lg\:tw-pr-2 {
      padding-right: 0.5rem !important;
    }

    .lg\:tw-pl-2 {
      padding-left: 0.5rem !important;
    }
  }
}
</style>

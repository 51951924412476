<template>
  <div></div>
</template>
<script>
import { anonFields, referrerTags } from '@/config/constants'

export default {
  name: 'AnonTracking',
  created() {
    if (this.$route.query) {
      const query = (this.$route.query.q) ? this.decrypt(this.$route.query.q) : this.$route.query

      anonFields.forEach(key => {
        if (query[key]) {
          this.$store.commit('anon/SET_ANON_DATA', { key: key, value: query[key] })
        }
      })

      if (query.r_id) {
        const referral = {}
        referrerTags.forEach(keys => {
          if (query[keys.q]) {
            referral[keys.key] = query[keys.q]
          }
        })
        this.$store.commit('anon/SET_REFERRAL_TRACKING', referral)
      }
    }
  },
  methods: {
    decrypt(val) {
      return JSON.parse(atob(decodeURIComponent(val)))
    },
  },
}
</script>
